import createPersistedState from 'use-persisted-state';
import { useCallback } from 'react';

const usePersistedState = createPersistedState('scope');

const useScopeState = (initialValue = '') => {
  const [scope, setScope] = usePersistedState(initialValue);
  const clearScope = useCallback(() => setScope(''), [setScope]); // is it really useful?

  return [scope, setScope, clearScope] as [typeof scope, typeof setScope, typeof clearScope];
};

export default useScopeState;
