import { useState } from 'react';
import createProvider from './createProvider';

const useLocalEntitiesState = () => useState({});
const [withEntitiesState, useEntitiesState] = createProvider(useLocalEntitiesState);

export { withEntitiesState, useEntitiesState };

const useEntities = () => useEntitiesState()[0];

export default useEntities;
