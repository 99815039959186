import moment from 'moment';
import { StudioBoxAssignationType, SubscriptionType } from 'app/types/index';

const getReservationEndDate: (
  studioBoxAssignation: StudioBoxAssignationType,
  subscription: SubscriptionType
) => Date | undefined = (studioBoxAssignation, subscription) => {
  if (!studioBoxAssignation || !subscription) return undefined;
  if (moment(studioBoxAssignation.enddate).isBefore(moment(subscription.validThrough)))
    return studioBoxAssignation.enddate as Date;
  return subscription.validThrough as Date;
};

export default getReservationEndDate;
