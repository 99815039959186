import createPersistedState from 'use-persisted-state';
import { useCallback } from 'react';

const usePersistedState = createPersistedState('saleId');

const useSaleState = (initialValue = '') => {
  const [saleId, setSaleId] = usePersistedState(initialValue);
  const clearSaleId = useCallback(() => setSaleId(''), [setSaleId]); // is it really useful?

  return [saleId, setSaleId, clearSaleId] as [typeof saleId, typeof setSaleId, typeof clearSaleId];
};

export default useSaleState;
