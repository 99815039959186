import moment from 'moment';
import 'moment/locale/fr';
import React, { Suspense, ComponentType } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { CandidacyWorkflowProvider, SubscribeWorkflowProvider, SubscriptionsWorkflowProvider } from 'app/contexts';
import { useIsAuthenticated, useConfig } from 'app/hooks'; // without this line it didn't work
import withProviders from 'app/providers';
import Layout from './Layout';
import { Helmet } from 'react-helmet';
moment.locale('fr');

const routes = [
  {
    exact: true,
    path: '/',
    component: React.lazy(() => import('../pages/Homepage'))
  },
  {
    exact: true,
    path: '/login',
    component: React.lazy(() => import('../pages/Login'))
  },
  {
    exact: true,
    path: '/subscribe',
    component: React.lazy(() => import('../pages/Subscribe/Form'))
  },
  {
    exact: true,
    path: '/our-boxes',
    component: React.lazy(() => import('../pages/OurBoxes/Boxes'))
  },
  {
    exact: true,
    path: '/our-offers',
    component: React.lazy(() => import('../pages/static/Offers'))
  },
  {
    exact: true,
    path: '/our-rules',
    component: React.lazy(() => import('../pages/static/Rules'))
  },
  {
    exact: true,
    path: '/legal-notice',
    component: React.lazy(() => import('../pages/static/LegalNotice'))
  },
  {
    exact: true,
    path: '/our-service',
    component: React.lazy(() => import('../pages/static/Service'))
  },
  {
    exact: true,
    path: '/contact',
    component: React.lazy(() => import('../pages/Contact/Form'))
  },
  {
    exact: true,
    path: '/lost-password',
    component: React.lazy(() => import('../pages/LostPassword/Form'))
  },
  {
    exact: true,
    path: '/lost-password/confirmation',
    component: React.lazy(() => import('../pages/LostPassword/Confirmation'))
  },
  {
    exact: true,
    path: '/reset-password',
    component: React.lazy(() => import('../pages/ResetPassword/Form'))
  },
  {
    exact: true,
    path: '/reset-password/confirmation',
    component: React.lazy(() => import('../pages/ResetPassword/Confirmation'))
  }
];

const privateRoutes = [
  {
    exact: true,
    path: '/subscribe/confirmation',
    component: React.lazy(() => import('../pages/Subscribe/Confirmation'))
  },
  {
    exact: true,
    path: '/subscribe/proof-of-address',
    component: React.lazy(() => import('../pages/Subscribe/ProofOfAddress'))
  },
  {
    exact: true,
    path: '/candidacies',
    component: React.lazy(() => import('../pages/Candidaties/CandidacyReservation'))
  },
  {
    exact: true,
    path: '/waiting-lists',
    component: React.lazy(() => import('../pages/WaitingLists/WaitingListsReservation'))
  },
  {
    exact: true,
    path: '/subscriptions',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/SubscriptionList'))
  },
  {
    exact: true,
    path: '/subscriptions/reservation',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/SubscriptionReservation'))
  },
  {
    exact: true,
    path: '/subscriptions/resume',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/Resume'))
  },
  {
    exact: true,
    path: '/subscriptions/payment-by-check',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/PaymentByCheck'))
  },
  {
    exact: true,
    path: '/subscriptions/confirmation/:invoiceId',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/Confirmation'))
  },
  {
    exact: true,
    path: '/reservation',
    component: React.lazy(() => import('../pages/ReservationProcess/StudioList'))
  },
  {
    exact: true,
    path: '/my-account',
    component: React.lazy(() => import('../pages/Account/MyAccount'))
  },
  {
    exact: true,
    path: '/my-reservation',
    component: React.lazy(() => import('../pages/Reservations/MyReservation'))
  },
  {
    exact: true,
    path: '/my-account/past-subscriptions',
    component: React.lazy(() => import('../pages/Account/PastSubscriptions'))
  },
  {
    exact: true,
    path: '/my-account/user-infos',
    component: React.lazy(() => import('../pages/Account/UserInfos'))
  },
  {
    exact: true,
    path: '/my-account/invoice-history',
    component: React.lazy(() => import('../pages/Account/InvoiceHistory'))
  },
  {
    exact: true,
    path: '/my-account/entry-history',
    component: React.lazy(() => import('../pages/Account/EntryHistory'))
  },
  {
    exact: true,
    path: '/my-account/my-waiting-list',
    component: React.lazy(() => import('../pages/Account/MyWaitingList'))
  },
  {
    exact: true,
    path: '/my-reservation/penalty',
    component: React.lazy(() => import('../pages/PenaltySaleProcess/PenaltySalePayment'))
  },
  {
    exact: true,
    path: '/my-reservation/penalty/:invoiceId',
    component: React.lazy(() => import('../pages/PenaltySaleProcess/Confirmation'))
  },
  {
    exact: true,
    path: '/my-account/my-badge',
    component: React.lazy(() => import('../pages/Badges/MyBadges'))
  },
  {
    exact: true,
    path: '/my-account/my-badges/add-badge',
    component: React.lazy(() => import('../pages/Badges/AddCard'))
  },
  {
    exact: true,
    path: '/subscriptions/extensions',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/SubscriptionExtensionList'))
  },
  {
    exact: true,
    path: '/subscriptions/extensions/resume',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/Resume'))
  },
  {
    exact: true,
    path: '/subscriptions/extensions/payment-by-check',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/PaymentByCheck'))
  },
  {
    exact: true,
    path: '/subscriptions/extensions/confirmation/:invoiceId',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/Confirmation'))
  }
];

const asyncFallback = <p style={{ textAlign: 'center' }}>Chargement ...</p>;
const NoMatch = React.lazy(() => import('../pages/NoMatch'));

const PrivateRoute: ComponentType<any> = ({ component, ...options }) => {
  const isAuthenticated = useIsAuthenticated();
  const finalComponent = isAuthenticated ? component : () => <Redirect to="/login" />;

  return <Route {...options} component={finalComponent} />;
};

const App: React.ComponentType = () => {
  const { favicon, title } = useConfig(config => config.theme);
  return (
    <div>
      <Helmet>
        {title && <title>{title}</title>}
        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>
      <Router>
        <SubscribeWorkflowProvider>
          <CandidacyWorkflowProvider>
            <Layout>
              <Suspense fallback={asyncFallback}>
                <Switch>
                  {routes.map((routeProps, routeIndex) => (
                    <Route key={routeIndex} {...routeProps} />
                  ))}
                  <SubscriptionsWorkflowProvider>
                    {privateRoutes.map((routeProps, routeIndex) => (
                      <PrivateRoute key={routeIndex} {...routeProps} />
                    ))}
                  </SubscriptionsWorkflowProvider>
                  <Route component={NoMatch} />
                </Switch>
              </Suspense>
            </Layout>
          </CandidacyWorkflowProvider>
        </SubscribeWorkflowProvider>
      </Router>
    </div>
  );
};

export default withProviders(App);
