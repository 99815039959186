import { useEffect, useState, useCallback, useMemo } from 'react';
import { useEntityManager, useDenormalizedState, useConnectedContact, useApiClient } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { IdentificatorType } from 'app/types';

const identificatorSchema = createUnionSchema(['identificators']);

const useIdentificators = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const client = useApiClient();
  const contact = useConnectedContact();
  const targetId = contact['@id'];

  const [isLoaded, setIsLoaded] = useState(false);
  const [identificators, setIdentificators] = useDenormalizedState<IdentificatorType[]>([], [identificatorSchema]);

  const fetch = useCallback(async () => {
    if (targetId !== undefined) {
      setIsLoaded(false);
      try {
        const data = await fetchEntityList(endpoints.IDENTIFICATORS, {
          params: {
            targetId: targetId
          }
        });
        setIdentificators(data.result);
        setIsLoaded(true);
      } catch (error) {
        setIsLoaded(true);
      }
    }
  }, [endpoints.IDENTIFICATORS, fetchEntityList, setIdentificators, targetId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const badges = useMemo(() => {
    return identificators.filter(item => {
      return item.type === 'badge' && item.badgeTechnology === null;
    });
  }, [identificators]);

  const passPassBadges = useMemo(() => {
    return identificators.filter(item => {
      return item.type === 'badge' && item.badgeTechnology === 'passpass';
    });
  }, [identificators]);

  const navigoBadges = useMemo(() => {
    return identificators.filter(item => {
      return item.type === 'badge' && item.badgeTechnology === 'navigo';
    });
  }, [identificators]);

  const qrCodes = useMemo(() => {
    return identificators.filter(item => {
      return item.type === 'qrcode';
    });
  }, [identificators]);

  const isOnlyQrCodes = useMemo(() => {
    return qrCodes.length === identificators.length;
  }, [identificators.length, qrCodes.length]);

  const fetchQrCode = useCallback(
    async (qrCodeId?: any, ext = 'html') => {
      try {
        if (!qrCodeId) {
          qrCodeId = qrCodes[0]['@id'];
        }

        const response = await client.request({
          url: `${qrCodeId}/print.${ext}`,
          method: 'get',
          responseType: 'arraybuffer'
        });

        return response.data;
      } catch (error) {}
    },
    [client, qrCodes]
  );

  const actions = {
    refresh: fetch,
    fetchQrCode
  };

  const data = {
    identificators: identificators,
    badges: badges,
    passPassBadges: passPassBadges,
    navigoBadges: navigoBadges,
    qrCodes: qrCodes,
    isOnlyQrCodes,
    isLoaded
  };

  return [data, actions] as [typeof data, typeof actions];
};

export default useIdentificators;
