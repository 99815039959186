import createPersistedState from 'use-persisted-state';
import { useCallback } from 'react';

const usePersistedState = createPersistedState('accessType');

const useAccessTypeState = (initialValue = '') => {
  const [accessType, setAccessType] = usePersistedState(initialValue);
  const clearAccessType = useCallback(() => setAccessType(''), [setAccessType]); // is it really useful?

  return [accessType, setAccessType, clearAccessType] as [
    typeof accessType,
    typeof setAccessType,
    typeof clearAccessType
  ];
};

export default useAccessTypeState;
